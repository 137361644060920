import React from 'react';
import styles from '../css/sections/Footer.module.css';

// Components
import Icon from '../components/Icon';

// other
import logo from '../images/logos/primary-mode.png';

const Footer = (props) => {
	const selectedMode = props.mode ? 'dark' : 'light';

	return (
		<div className={`${styles.footer} ${styles[selectedMode]}`}>
			<Icon src={logo} size="xs" align="center" mode={props.mode} />
			<h3 className={styles.version}>official-site.v.03</h3>
			<h3 className={styles.version}>
				© 2022 Jorge Perez. All right reserved.
			</h3>
		</div>
	);
};

export default Footer;
