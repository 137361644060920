import brain from './images/brain.svg';
import blocks from './images/blocks.svg';
import ruler from './images/ruler.svg';

export const projects = [
	{
		id: 1,
		title: 'Tic-Tac-Toe AI',
		desc: 'In this project, we dipped our toes into deep learning. We were given two weeks to implement any of the given branches of machine learning. It is not the most sophisticated project, but a memorable learning experience nonetheless.',
	},
	{
		id: 2,
		title: 'Neural Net',
		desc: "Learning the intricacies of neural networks was the first time I felt a true understanding of modern AI. It wasn't until recently that I have felt the desire to work closer with Neural Nets. In this project, we studied the effectiveness and deficiencies of activation functions.",
	},
	{
		id: 3,
		title: 'Color Quantization',
		desc: 'Identifying and implementing efficiency is at the heart of our profession. A great illustration of this for me was this simple project. I can see how applying k-means clustering to other characteristics than just visuals is useful for memory management or even character identification.',
	},
	{
		id: 4,
		title: 'v6File Reader',
		desc: 'Operating Systems was not even close to being one of my favorite classes, however, this final project was one I enjoyed. For once, I spent more time doing research and familiarizing myself with the filing system rather than coding. In addition, it is some of my cleanest code.',
	},
];

export const courses = [
	{
		id: 1,
		img: brain,
		title: 'machine learning',
		desc: 'Machine learning was an electoral course for me, and boy, am I glad in my selection. Not only was I introduced to the world of machine learning, but I was also allowed to improve my python with libraries like Numpy and Pandas. I look forward to diving deeper.',
	},
	{
		id: 2,
		img: blocks,
		title: 'd.s. & algorithms',
		desc: 'Most of my favor towards this course mostly had to do with the simplification by the instructor. Nonetheless, these fundamentals will be present in everyday work. I will have to revisit the material in this course for the rest of my career.',
	},
	{
		id: 3,
		img: ruler,
		title: 'project planning',
		desc: 'There were many stories shared in this course by the instructor to illustrate the bittersweet life of a project manager. I would love to journey through the scrambles of a project as the leader in the later part of my career, after some experience.',
	},
];
