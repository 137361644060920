import React from 'react';
import styles from '../css/components/Card.module.css';

function Card(props) {
	return (
		<div
			className={`${styles.card} ${styles[props.mode]}`}
			style={{
				background: `${props.background}`,
				height: `${props.height}`,
				width: `${props.width}`,
			}}
		>
			{props.img && (
				<img className={styles.img} src={props.img} alt={props.alt} />
			)}
			{props.content}
			<h1 className={styles['card-title']}>{props.title}</h1>
			<p className={styles['card-desc']}>{props.desc}</p>
		</div>
	);
}

export default Card;
