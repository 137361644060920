import React from 'react';
import styles from '../css/components/Icon.module.css';

function Icon(props) {
	return (
		<img
			className={`${styles.icon} ${
				props.mode ? styles['dark'] : styles['light']
			} ${styles[props.size]} ${styles[props.align]}`}
			src={props.src}
			alt={props.alt}
			onClick={props.onClick}
		/>
	);
}

export default Icon;
