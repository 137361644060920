import React from 'react';
import styles from '../css/components/Header.module.css';

import Icon from './Icon';
import logoLight from '../images/logos/light-mode.png';
import logoDark from '../images/logos/dark-mode.png';

const Header = (props) => {
	const selectedMode = props.mode ? 'dark' : 'light';
	const logoMode = selectedMode === 'light' ? logoLight : logoDark;

	return (
		<div className={`${styles.header} ${styles[selectedMode]}`}>
			<Icon src={logoMode} mode={props.mode} size="sm" alt="logo" />
			<h1
				className={`${styles['header-content']} ${styles['roman-numeral']}`}
				style={{ color: `${props.romColor}` }}
			>
				{props.romanNum}
			</h1>
			<h1
				className={styles['header-content']}
				style={{ color: `${props.headerColor}` }}
			>
				{props.header}
			</h1>
		</div>
	);
};

export default Header;
