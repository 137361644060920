import React, { Component } from 'react';
import styles from '../css/sections/Hero.module.css';

import Icon from '../components/Icon';
import logo from '../images/logos/primary-mode.png';
// import Ticker from '../components/Ticker';

const Hero = (props) => {
	const modeHandler = () => props.onModeChange();

	return (
		<div className={styles.hero}>
			<div className={styles.head}>
				<Icon
					mode={props.mode}
					size="sm"
					src={logo}
					alt="logo"
					align="center"
					onClick={modeHandler}
				/>
				<h1 className={`${styles.name} ${props.mode && styles['dark-name']}`}>
					Jor<span>ge</span>
				</h1>
				<ul className={`${styles.titles} ${props.mode && styles['dark']}`}>
					<li className={styles.title}>software engineer</li>
					<li className={styles.title}>webDev</li>
					<li className={styles.title}>m. learning</li>
				</ul>
			</div>
		</div>
	);
};

export default Hero;
