import React from 'react';
import styles from '../css/sections/Experience.module.css';

// Components
import Header from '../components/Header';
import Card from '../components/Card';
import JobCard from '../components/JobCard';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLinesVertical } from '@fortawesome/free-solid-svg-icons';

// other
import TMO from '../images/logos/t-mobile.png';
import TD from '../images/logos/td.png';
import USAA from '../images/logos/usaa.png';
import { projects } from '../CardInfo';

const Experience = (props) => {
	const selectedMode = props.mode ? 'dark' : 'light';
	const cardColor = props.mode ? '#1b1b1b' : 'transparent';

	const projectList = projects.map((project) => (
		<Card
			key={project.id}
			background={cardColor}
			width="250px"
			height="325px"
			title={project.title}
			desc={project.desc}
			mode={selectedMode}
		/>
	));

	return (
		<div className={`${styles.experience} ${styles[selectedMode]}`}>
			<Header
				header="Experience"
				headerColor="#5FE0B7"
				romanNum="I"
				mode={props.mode}
			/>
			<div className={styles.content}>
				<p className={styles.intro}>
					I have a long way to go, but for that reason, I am eager to get right
					into contributing to the technological space. Here are some of my work
					experiences and favorite projects. I look forward to many more.
					Cheers!
				</p>
				<Card
					background={cardColor}
					width="280px"
					height="115px"
					title="backpack"
					mode={selectedMode}
				/>
				<div className={styles.work}>
					<h1 className={styles.header}>unrelated work</h1>
					<JobCard
						companyName="Taco Delite"
						companyLogo={TD}
						jobTitle="Manager"
						jobTimeline="Feb 2013 - Jul 2018"
						companyColor="#40B650"
						mode={selectedMode}
					/>
					<FontAwesomeIcon
						className={styles.timeline}
						icon={faGripLinesVertical}
						size="5x"
					/>
					<JobCard
						companyName="T-Mobile"
						companyLogo={TMO}
						jobTitle="Sales Associate"
						jobTimeline="Jul 2019 - Aug 2021"
						companyColor="#E10068"
						mode={selectedMode}
					/>
					<FontAwesomeIcon
						className={styles.timeline}
						icon={faGripLinesVertical}
						size="4x"
					/>
					<h1 className={styles.header}>related work</h1>
					<JobCard
						companyName="USAA"
						companyLogo={USAA}
						jobTitle="Software Engineer III"
						jobTimeline="Jan 2022 - "
						companyColor="#1A3258"
						mode={selectedMode}
					/>
				</div>
				<div className={styles.projects}>
					<h1 className={styles.header}>projects</h1>
					{projectList}
				</div>
			</div>
		</div>
	);
};

export default Experience;
