import React from 'react';
import styles from '../css/sections/Education.module.css';

// Components
import Header from '../components/Header';
import Card from '../components/Card';

// other
import UTD from '../images/utd-courtyard.png';
import brain from '../images/brain.svg';
import blocks from '../images/blocks.svg';
import ruler from '../images/ruler.svg';
import { courses } from '../CardInfo';

const Education = (props) => {
	const selectedMode = props.mode ? 'dark' : 'light';
	const headerColor = props.mode ? '#ffd9b3' : '#E87500';
	const cardColor = props.mode ? '#1b1b1b' : 'transparent';

	const courseList = courses.map((course) => (
		<Card
			key={course.id}
			background={cardColor}
			width="250px"
			height="325px"
			img={course.img}
			title={course.title}
			desc={course.desc}
			mode={selectedMode}
		/>
	));

	return (
		<div className={`${styles.education} ${styles[selectedMode]}`}>
			<div className={styles['education-orange']}>
				<Header
					header="Education"
					romanNum="II"
					headerColor={headerColor}
					romColor={headerColor}
					mode={props.mode}
				/>
				<p className={styles.intro}>
					I had quite an undergraduate journey going from Collin College to
					Baylor University and reaching my final home, UTD. Here are some of
					the courses that encouraged my development of computer science and
					software engineering.
				</p>
				<div className={styles.university}>
					<div>
						<h1 className={styles['head-univ']}>Fall 2020</h1>
						<h2 className={styles['sub-univ']}>
							University of Texas at Dallas
						</h2>
						<h2 className={styles['sub-univ']}>B.S. in Software Engineer</h2>
						<img className={styles['img-utd']} src={UTD} alt="utd" />
					</div>
				</div>
			</div>
			<div className={styles['courses']}>
				<h1 className={styles['header']}>favorite Courses</h1>
				<div className={styles['course-cards']}>{courseList}</div>
			</div>
		</div>
	);
};

export default Education;
