import React from 'react';
import styles from '../css/components/JobCard.module.css';

const JobCard = (props) => {
	const getLuma = (hex) => {
		const subHex = hex.substring(1);
		const rgb = parseInt(subHex, 16);
		const r = (rgb >> 16) & 0xff;
		const g = (rgb >> 8) & 0xff;
		const b = (rgb >> 0) & 0xff;
		return 0.2126 * r + 0.7152 * g + 0.0722 * b;
	};

	return (
		<div className={`${styles.jobcard} ${styles[props.mode]}`}>
			<div className={styles['job-head']}>
				<h1
					className={styles['company-name']}
					style={{
						color: `${props.companyColor}`,
						filter: `${
							props.mode === 'dark' && getLuma(props.companyColor) < 50
								? 'brightness(3)'
								: 'brightness(1)'
						}`,
					}}
				>
					{props.companyName}
				</h1>
				{props.companyLogo && (
					<img
						className={styles['company-logo']}
						src={props.companyLogo}
						alt={props.alt}
						style={{
							filter: `${
								props.mode === 'dark' && getLuma(props.companyColor) < 50
									? 'brightness(3)'
									: 'brightness(1)'
							}`,
						}}
					/>
				)}
			</div>
			<h2 className={styles['job-title']}>{props.jobTitle}</h2>
			<h2 className={styles['job-timeline']}>{props.jobTimeline}</h2>
		</div>
	);
};

export default JobCard;
