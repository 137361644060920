import React, { useState } from 'react';
import './App.css';

// Sections
import Hero from './sections/Hero';
import Experience from './sections/Experience';
import Education from './sections/Education';
import Ticker from './components/Ticker';
import Footer from './sections/Footer';

const App = () => {
	const [darkMode, setDarkMode] = useState(0);

	const modeChangeHandler = () => {
		setDarkMode(!darkMode);
	};

	return (
		<div className="App">
			<Hero onModeChange={modeChangeHandler} mode={darkMode} />
			<Ticker mode={darkMode} />
			<Experience mode={darkMode} />
			<Education mode={darkMode} />
			<Footer mode={darkMode} />
		</div>
	);
};

export default App;
