import React from 'react';
import styles from '../css/components/Ticker.module.css';

function Ticker(props) {
	return (
		<div className={`${styles.ticker} ${props.mode && styles['dark']}`}></div>
	);
}

export default Ticker;
